export default (i18n) => {
  return {
    path: '/dashboard/nova',
    component: () => import('@modules/dashboard-nova/views/Dashboard.vue'),
    meta: {
      site: {
        name: i18n.global.t('app.dashboard'),
        t: 'app.dashboard',
      },
    },
    children: [
      {
        path: '',
        name: 'dashboard-nova',
        redirect: {
          name: 'dashboard-general-nova',
        },
      },
      {
        path: 'general',
        name: 'dashboard-general-nova',
        component: () => import('@modules/dashboard-nova/views/General.vue'),
        meta: {
          title: 'dashboard.conversations',
          site: {
            t: 'dashboard.conversations',
          },
        },
      },
    ],
  };
};
